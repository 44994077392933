import { CookieConsentProps } from 'react-cookie-consent';

export const NPM_PACKAGE_NAME = process.env.npm_package_name;
export const NPM_PACKAGE_VERSION = process.env.npm_package_version;

export const NODE_ENV = process.env.REACT_APP_NODE_ENV;
export const IS_DEV = !NODE_ENV || NODE_ENV === 'development';

export const DEFAULT_DATA_FORMAT = 'dd/MM/yyyy HH:mm';

// Toast
export const DEFAULT_DURATION_MS = 5000;

// Privacy
export const PRIVACY_COOKIE_MESSAGE =
  "Questo sito utilizza i cookie per migliorare l'esperienza dell'utente.";

export const PRIVACY_COOKIE_PROPS: Partial<CookieConsentProps> = {
  location: 'bottom',
  cookieName: 'vieniora-com-privacy',
  buttonText: 'Accetta',
  declineButtonText: 'Rifiuta',
  enableDeclineButton: true,
  acceptOnScroll: true,
  style: { background: '#2B373B', zIndex: 50 },
  buttonStyle: { background: '#ec4899', color: '#fff', fontSize: '18px' },
  declineButtonStyle: { background: '#eb4034', color: '#fff', fontSize: '18px' },
  expires: 150,
};

// Local Storage
export const LOCAL_STORAGE_KEY = {
  AUTH_TOKEN: 'auth-token',
  ADULT_CONSENT_MODAL: 'adult-consent-modal',
  COMMUNICATION_TOKEN: 'communication-token',
  SOCKET_ACCESS_TOKEN: 'socket-access-token',
  SOCKET_REFRESH_TOKEN: 'socket-refresh-token',
  PUSH_SUBSCRIPTION_TOKEN: 'push-subscription-token',
};

// CDN
export const CDN_ENDPOINT = {
  STATIC: process.env.REACT_APP_CDN_ENDPOINT_STATIC || '',
  CHAT: process.env.REACT_APP_CDN_ENDPOINT_CHAT || '',
};

// Contacts platforms
export const PLATFORM = {
  WEB: 'WEB',
  SKYPE: 'SKYPE',
  TELEGRAM: 'TELEGRAM',
  INSTAGRAM: 'INSTAGRAM',
};

export const DEFAULT_GATEWAY = 'VEROTEL';

export const IS_ENABLE_SIGNUP_CREATOR: boolean = !process.env
  .REACT_APP_IS_ENABLE_SIGNUP_CREATOR
  ? true
  : process.env.REACT_APP_IS_ENABLE_SIGNUP_CREATOR === 'true'
    ? true
    : false;

export const ID_ACCOUNT_EPOCH = -1;

export enum LOGO_URI {
  MONEDIA = '/assets/vieniora.png',
  VIENIORA = '/assets/vieniora.png',
}

export enum SITE_NAME {
  MONEDIA = 'Vieniora',
  VIENIORA = 'Vieniora',
}

export enum SITE_EMAIL_SUPPORT {
  MONEDIA = 'support@monedia.com',
  VIENIORA = 'support@vieniora.com',
}

export const CURRENT_LOGO_URI = IS_DEV ? LOGO_URI.MONEDIA : LOGO_URI.VIENIORA;

export const CURRENT_SITE_NAME = IS_DEV ? SITE_NAME.MONEDIA : SITE_NAME.VIENIORA;

export const CURRENT_SITE_EMAIL_SUPPORT = IS_DEV
  ? SITE_EMAIL_SUPPORT.MONEDIA
  : SITE_EMAIL_SUPPORT.VIENIORA;
